@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Outfit:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-size: 16px;

    @screen md {
      font-size: 18px;
    }
  }

  body {
    @apply min-w-full;
  }

  h1,
  h2,
  h3,
  h4 {
    @apply mt-5 first:mt-0 font-heading;
  }

  h1 {
    @apply text-5xl font-thin;
  }

  h2 {
    @apply text-3xl font-thin;
  }

  h3 {
    @apply text-brand text-xl;
  }

  a {
    @apply hover:text-brand transition-all ease-in-out;
  }

  p {
    @apply mt-5 first:mt-0;

    a {
      @apply text-brand hover:text-secondary;
    }
  }

  blockquote {
    @apply rounded-md mt-5 first:mt-0 p-5 border border-gray-200 bg-gray-100;
  }

  ul,
  ol {
    @apply mt-5 first:mt-0;
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }

  li {
    @apply ml-5;
  }

  label {
    @apply block;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  input[type="number"],
  textarea {
    @apply rounded block w-full p-2 border-2 border-gray-200 focus:border-brand bg-gray-100 placeholder:text-gray-300 focus:outline-none;
  }
}

@layer components {
  .wrapper {
    @apply w-full min-w-min max-w-6xl mx-auto px-5 md:px-10;
  }

  .hero {
    background-image: url("../img/hero-bg.webp");

    @apply flex w-full flex-1 bg-center bg-cover;

    &-hue {
      @apply flex-1 bg-brand bg-opacity-80 text-white;

      a {
        @apply text-accent hover:text-white;
      }
    }

    &-content {
      @apply flex flex-col md:flex-row justify-start items-start md:space-x-10 space-y-10 md:space-y-0;
    }
  }

  .agents {
    background-image: url("../img/lucas-and-maricela.webp");
  }

  .home {
    .splide__arrow svg {
      @apply fill-brand;
    }

    .splide__arrow:hover:not(:disabled) svg {
      @apply fill-secondary;
    }

    .splide__arrow--prev {
      left: 0;
    }

    .splide__arrow--next {
      right: 0;
    }
  }

  .article {
    &-header {
      @apply py-10 bg-brand text-white;
    }

    &-aside {
      @apply rounded w-full lg:w-80 p-5 space-y-10 border border-gray-100 bg-gray-50 text-sm;

      h1 {
        @apply text-3xl;
      }
    }
  }

  .video-frame {
    @apply rounded overflow-hidden border border-gray-200;
  }

  .form {
    &-container {
      @apply rounded overflow-hidden p-5 bg-white text-black text-left;
    }

    &-label {
      @apply block mb-2 text-sm font-medium;
    }

    &-group {
      @apply mt-3 first:mt-0;

      input[type="text"],
      input[type="email"],
      input[type="tel"] {
        @apply border-0;
      }
    }
  }

  .button {
    &-primary {
      @apply shadow hover:shadow-lg rounded-full py-3 px-6 bg-brand hover:bg-secondary focus:outline-none text-center text-xl text-white hover:text-white font-heading font-light uppercase transition transform ease-in-out duration-300 active:translate-y-1;

      &.inverted {
        @apply bg-white hover:bg-white text-brand hover:text-secondary;
      }

      &.small {
        @apply py-1;
      }
    }

    &-secondary {
      @apply inline-block rounded-lg mt-5 px-5 py-2.5 bg-brand hover:bg-secondary text-white hover:text-white;
    }
  }

  a.button-primary {
    @apply text-white;
  }

  .buy {
    @apply h-24 bg-center bg-contain bg-no-repeat;

    &-1 {
      background-image: url("../img/buy1.png");
    }
    &-2 {
      background-image: url("../img/buy2.png");
    }
    &-3 {
      background-image: url("../img/buy3.png");
    }
    &-4 {
      background-image: url("../img/buy4.png");
    }
    &-5 {
      background-image: url("../img/buy5.png");
    }
    &-6 {
      background-image: url("../img/buy6.png");
    }
  }

  .sell {
    &-1 {
      background-image: url("../img/sell1.png");
    }
    &-2 {
      background-image: url("../img/sell2.png");
    }
    &-3 {
      background-image: url("../img/sell3.png");
    }
    &-4 {
      background-image: url("../img/sell4.png");
    }
    &-5 {
      background-image: url("../img/sell5.png");
    }
    &-6 {
      background-image: url("../img/sell6.png");
    }
  }

  .cash-offer {
    background-image: url("../img/billie-dollar-money-background-web.jpg");

    @apply text-white bg-cover bg-no-repeat bg-center;
  }

  .home-evaluation {
    background-image: url("../img/home-evaluation-bg.webp");
  }
}
